export const skillsDict = {
  angular: "Angular",
  bash: "Bash",
  c: "C",
  "c#": "C#",
  "c++": "C++",
  css: "CSS",
  django: "Django",
  express: "Express",
  figma: "Figma",
  flask: "Flask",
  flutter: "Flutter",
  go: "Go",
  html: "HTML",
  java: "Java",
  javascript: "JavaScript",
  laravel: "Laravel",
  lua: "Lua",
  mongodb: "MongoDB",
  mysql: "MySQL",
  nextjs: "NextJS",
  numpy: "NumPy",
  php: "PhP",
  postgresql: "PostgreSQL",
  prisma: "Prisma",
  python: "Python",
  react: "React",
  reactnative: "React Native",
  rust: "Rust",
  spring: "Spring",
  svelte: "Svelte",
  sveltekit: "SvelteKit",
  swift: "Swift",
  swiftui: "SwiftUI",
  tailwind: "Tailwind",
  tensorflow: "TensorFlow",
  typescript: "TypeScript",
  vuejs: "VueJS",
  websockets: "WebSockets",
  xcode: "Xcode",
};

export const skills = [
  ...Object.keys(skillsDict).map((e) => ({ value: e, label: skillsDict[e] })),
];
